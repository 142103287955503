define('ember-js-getting-started/components/general-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getTotalApiCredits = function getTotalApiCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits/api',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalApiCreditsLoading', false);
        self.set('totalApiCredits', response);
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalApiCreditsLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalApiCreditsLoading', false);
      }
    });
  };

  var getTotalCredits = function getTotalCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalCreditsLoading', false);
        self.set('totalCredits', response);
        //$("#chernobyl").modal('show');
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      }
    });
  };

  var getAutoresponderQuota = function getAutoresponderQuota(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/autoresponder',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalArLoading', false);
        self.set('totalAr', response);
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalArLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalArLoading', false);
      }
    });
  };

  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('userLoading', false);
        self.set('user', response);
        if (self.get('user.planName') === 'API') {
          self.set('planApi', true);
        } else {
          self.set('planApi', false);
        }
      },
      failure: function failure(response) {
        self.set('userLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userLoading', false);
        console.log(response);
      }
    });
  };

  var getTotalUsers = function getTotalUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users/additional/info',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalUsersLoading', false);
        self.set('totalUsers', response);
      },
      failure: function failure(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      }
    });
  };

  var getMachines = function getMachines(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/userMachineSlots/list',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('machinesLoading', false);
        self.set('machines', response);
      },
      failure: function failure(response) {
        self.set('machinesLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('machinesLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    totalCreditsLoading: true,
    totalArLoading: true,
    machinesLoading: true,
    userLoading: true,
    totalUsersLoading: true,
    totalApiCreditsLoading: true,

    initComponente: Ember.on('init', function () {
      //$("#chernobyl").modal('show');
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);
      this.set('planExpired', token.expired);

      getTotalCredits(this, token);
      getAutoresponderQuota(this, token);
      getMachines(this, token);
      getUser(this, token);
      getTotalUsers(this, token);
      getTotalApiCredits(this, token);

      //$("#chernobyl").modal('show');
    }),

    isPlanInfinity: function () {
      if (this.get('authenticationService').get().extras.user.planName === 'Infinity' || this.get('authenticationService').get().extras.user.planName === 'Infinity B' || this.get('authenticationService').get().extras.user.planName === 'Infinity T') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanInfinity'),

    planApi: function () {
      return true;
    }.property('planApi'),

    planExpired: function () {
      return null;
    }.property('planExpired'),

    userAdditional: function () {
      return null;
    }.property('userAdditional'),

    totalCredits: function () {
      return null;
    }.property('totalCredits'),

    totalApiCredits: function () {
      return null;
    }.property('totalApiCredits'),

    totalAr: function () {
      return null;
    }.property('totalAr'),

    totalMachines: function () {
      return null;
    }.property('totalMachines'),

    user: function () {
      return null;
    }.property('user'),

    totalUsers: function () {
      return null;
    }.property('totalUsers'),

    actions: {
      toForgram: function toForgram() {
        window.open("https://forgram.com.br/", "_self");
      },
      reactivate: function reactivate() {
        this.get('authenticationService').logoff(this);
        window.open("https://purchase.hotmart.com/purchase", "_self");
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toMem: function toMem() {
        this.get('routing').transitionTo('panel.mem');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      },
      toEmojis: function toEmojis() {
        this.get('routing').transitionTo('panel.emoji');
      },
      toApi: function toApi() {
        this.get('routing').transitionTo('panel.api');
      },
      closeCommunit: function closeCommunit() {
        if (this.get('authenticationService').get().extras.user.planName === 'Infinity' || this.get('authenticationService').get().extras.user.planName === 'Infinity B' || this.get('authenticationService').get().extras.user.planName === 'Infinity T' || this.get('authenticationService').get().extras.user.planName === 'Mid' || this.get('authenticationService').get().extras.user.planName === 'Mid B' || this.get('authenticationService').get().extras.user.planName === 'Mid T') {
          window.open("https://www.facebook.com/groups/2012075535562230/", "_blank");
        } else {
          $("#closeCommunityModal").modal('show');
        }
      },
      toLive: function toLive() {
        //$("#learnWating").modal('show');
        this.get('routing').transitionTo('panel.learn');
      },
      showNfeModal: function showNfeModal() {
        var self = this;
        var token = self.get('authenticationService').get();

        self.set('nfeModalLoading', true);
        $('#showNfeModal').modal('show');

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/nfes',
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('nfeModalLoading', false);
            self.set('nfeList', response);
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('nfeModalLoading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('nfeModalLoading', false);
            console.log(response);
          }
        });
      }
    }

  });
});