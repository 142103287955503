define('ember-js-getting-started/routes/support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect() {
      //teste
      var url = window.location.href;
      var email = url.split('?user=')[1];
      window.open("https://api.whatsapp.com/send?phone=5517988058002&text=Gostaria de solicitar troca de plano para o email de cadastro: " + email, "_self");
    }
  });
});