define('ember-js-getting-started/components/learn-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getLearnEbook = function getLearnEbook(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/learns/ebook',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('learnEbookLoading', false);
        self.set('learnEbooks', response);
      },
      failure: function failure(response) {
        self.set('learnEbookLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('learnEbookLoading', false);
        console.log(response);
      }
    });
  };

  var getLearnVideo = function getLearnVideo(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/learns/video',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('learnVideoLoading', false);
        self.set('learnVideos', response);
      },
      failure: function failure(response) {
        self.set('learnVideoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('learnVideoLoading', false);
        console.log(response);
      }
    });
  };

  var getLearnVideoComment = function getLearnVideoComment(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/learns/video/' + self.get('objectVideoModal.id') + '/comment/paginate?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('learnVideoCommentLoading', false);
        self.set('learnVideoCommentList', response);
      },
      failure: function failure(response) {
        self.set('learnVideoCommentLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('learnVideoCommentLoading', false);
        console.log(response);
      }
    });
  };

  var refresh = function refresh(self) {
    var token = self.get('authenticationService').get();
    self.set('learnVideoCommentLoading', true);
    getLearnVideoComment(self, token);
  };

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),

    authenticationService: Ember.inject.service('auth-token-service'),

    learnEbookLoading: true,

    learnVideoLoading: true,

    learnVideoCommentLoading: false,

    objectVideoModal: null,

    searchPerPage: 10,
    searchPage: 1,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getLearnEbook(this, token);
      getLearnVideo(this, token);

      var handler;
      var self = this;
      handler = setInterval(function () {
        if (Ember.getOwner(self).lookup('controller:application').currentPath === 'panel.learn') {
          console.log('clock: ' + new Date());
          refresh(self);
        }
      }, 30000); //60000 1 minutos
      this.set('handler', handler);
    }),

    teardown: function () {
      clearInterval(this.get('handler'));
    }.on('willDestroyElement'),

    learnEbooks: function () {
      return null;
    }.property('learnEbooks'),

    learnVideos: function () {
      return null;
    }.property('learnVideos'),

    actions: {
      refresh: function refresh() {},
      openVideo: function openVideo(video) {
        var token = this.get('authenticationService').get();

        this.set('objectVideoModal', video);
        $("#learnVideoModal").modal('show');
        this.set('learnVideoCommentLoading', true);

        getLearnVideoComment(this, token);
      },
      nextPage: function nextPage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('learnVideoCommentList.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        this.set('learnVideoCommentLoading', true);
        getLearnVideoComment(this, token);
      },
      beforePage: function beforePage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        this.set('learnVideoCommentLoading', true);
        getLearnVideoComment(this, token);
      },
      setNewComment: function setNewComment() {
        var token = this.get('authenticationService').get();
        var self = this;

        if (self.get('newComment') === null || self.get('newComment') === undefined || self.get('newComment') === "" || self.get('newComment') === " ") {
          alert('Faça um comentário antes de inserir uma atualização.');
          return;
        }

        self.set('learnVideoCommentLoading', true);

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/learns/video/comment/insert',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: '{"idLearnVideo":"' + self.get('objectVideoModal.id') + '",' + '"description":"' + self.get('newComment') + '"}',

          success: function success(response) {
            self.set('learnVideoCommentLoading', false);
            self.set('newComment', null);
            getLearnVideoComment(self, token);
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          }
        });
      }
    }

  });
});