define('ember-js-getting-started/components/emoji-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),

    emojiPessoas: true,
    objetos: false,
    comidasBebidas: false,
    atividades: false,
    viagensLugares: false,
    simbolos: false,
    animaisNatureza: false,

    actions: {
      goToObjetos: function goToObjetos() {
        this.set('emojiPessoas', false);
        this.set('objetos', true);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToEmojiPessoas: function goToEmojiPessoas() {
        this.set('emojiPessoas', true);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToComidasBebidas: function goToComidasBebidas() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', true);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToAtividades: function goToAtividades() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', true);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToViagensLugares: function goToViagensLugares() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', true);
        this.set('simbolos', false);
        this.set('animaisNatureza', false);
      },
      goToSimbolos: function goToSimbolos() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', true);
        this.set('animaisNatureza', false);
      },
      goToAnimaisNatureza: function goToAnimaisNatureza() {
        this.set('emojiPessoas', false);
        this.set('objetos', false);
        this.set('comidasBebidas', false);
        this.set('atividades', false);
        this.set('viagensLugares', false);
        this.set('simbolos', false);
        this.set('animaisNatureza', true);
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }
  });
});