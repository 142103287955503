define('ember-js-getting-started/components/navigation-infos-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getNotifications = function getNotifications(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/notifications/last',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('nfLoading', false);
        self.set('notifications', response);
        console.log(self.get('notifications'));
      },
      failure: function failure(response) {
        self.set('nfLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('nfLoading', false);
        console.log(panelInfoLoading);
      }
    });
  };

  exports.default = Ember.Component.extend({
    initComponent: Ember.on('init', function () {
      getNotifications(this, null);
    })
  });
});