define('ember-js-getting-started/components/api-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getQrCode = function getQrCode(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/whatsapp/login',
      type: 'POST',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token
      },
      data: null,
      success: function success(response) {
        self.set('qrcode', response);

        self.set('qrTimer', response.data.timeout);

        self.set('qrRefreshLoading', false);
      },
      failure: function failure(response) {
        self.set('qrRefreshLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('qrRefreshLoading', false);
        console.log(response);
      }
    });
  };

  var getApiToken = function getApiToken(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/whatsapp/token',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,

      success: function success(response) {
        self.set('apiTokenLoading', false);
        self.set('token', response.authorizationToken);
      },

      failure: function failure(response) {
        self.set('apiTokenLoading', true);

        console.log(response);
      },

      error: function error(response) {
        self.set('apiTokenLoading', true);

        console.log(response);
      }

    });
  };

  var newApiToken = function newApiToken(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/whatsapp/token/create',
      type: 'POST',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('apiTokenLoading', false);
        self.set('token', response.authorizationToken);
      },
      failure: function failure(response) {
        self.set('apiTokenLoading', true);
        console.log(response);
      },
      error: function error(response) {
        self.set('apiTokenLoading', true);
        console.log(response);
      }

    });
  };

  var refreshQR = function refreshQR(self) {
    var qrToken = self.get('token');
    self.set('qrcode', null);
    self.set('qrRefreshLoading', true);
    getQrCode(self, qrToken);
  };

  exports.default = Ember.Component.extend({

    authenticationService: Ember.inject.service('auth-token-service'),

    routing: Ember.inject.service('-routing'),

    //spinners
    qrRefreshLoading: false,
    apiTokenLoading: true,

    //timer do qrcode
    qrTimer: false,

    //dados qr
    qrcode: null,
    //token da api
    token: null,

    endpoint: 'https://wss.socialmaker.com.br/api/whatsapp',

    initComponente: Ember.on('init', function () {

      var self = this;
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getApiToken(this, token);

      var currentRoute = self.get('routing').get('currentRouteName');
      //verificando se está na rota correta pra executar o cron
      if (currentRoute === 'panel.api') {
        //cron do componente
        setInterval(function () {

          if (self.get('qrTimer') !== false && currentRoute === 'panel.api') {
            self.set('qrTimer', self.get('qrTimer') - 1);

            if (self.get('qrTimer') === 0) {
              self.set('qrTimer', 30); // expired

              //refreshQR
              self.set('qrcode', null);
              self.set('qrRefreshLoading', true);
              getQrCode(self, self.get('token'));
            }
          }
        }, 1000); //1000 1 segundo
      }
    }),

    actions: {
      newToken: function newToken() {
        this.set('apiTokenLoading', true);
        newApiToken(this, this.get('authenticationService').get());
      },
      refreshQR: function refreshQR() {
        var qrToken = this.get('token');
        this.set('qrTimer', 30);
        this.set('qrcode', null);
        this.set('qrRefreshLoading', true);
        getQrCode(this, qrToken);
      }
    }

  });
});