define('ember-js-getting-started/components/download-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getDtVersion = function getDtVersion(self, token, page, perPage) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/versions/desktop/socialmaker',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('loadingDtVersion', false);

        self.set('dtVersion', response);

        var dtVersionLessDot = self.get('dtVersion.version').replace(".", "").replace(".", "").replace(".", "");

        if (navigator.userAgent.indexOf("WOW64") != -1 || navigator.userAgent.indexOf("Win64") != -1) {
          //self.set('url',self.get('url') + dtVersionLessDot + 'x64' + '.exe');
          self.set('url', 'https://socialmaker-scripts.s3.amazonaws.com/download/Socialmaker_Instalador.exe');
        } else {
          //self.set('url',self.get('url') + dtVersionLessDot + 'x86' + '.exe');
          self.set('url', 'https://socialmaker-scripts.s3.amazonaws.com/download/Socialmaker_Instalador.exe');
        }

        //self.set('url',self.get('url') + '_Instalador' + '.exe');
      },
      failure: function failure(response) {
        self.set('loadingDtVersion', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingDtVersion', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    loadingDtVersion: true,

    url: 'https://socialmaker-service.s3.sa-east-1.amazonaws.com/download/Socialmaker',

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);
      getDtVersion(this);
    }),

    dtVersion: function () {
      return null;
    }.property('dtVersion'),

    actions: {
      download: function download() {
        window.open(this.get('url'), "_self");
      },
      openModal: function openModal() {
        $("#managerModal").modal("show");
      }
    }

  });
});