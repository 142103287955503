define('ember-js-getting-started/routes/login', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    //Verificando autenticação 
    beforeModel: function beforeModel() {
      var url_string = window.location.href;
      var http = url_string.split('://')[0];

      if (http === 'http') {
        var localhost = url_string.split('://')[1];
        var localhost = url_string.split('/#/')[0];

        if (localhost === 'http://localhost:4200/') {
          //nada
        } else {
          if (localhost === 'https://gerador.socialmaker.com.br/' || localhost === 'https://gerador.socialmaker.com.br') {
            window.location.replace("https://gerador.socialmaker.com.br/#/widget-socialmedia");
          } else {
            //window.location.replace("https://painel.socialmaker.com.br/");
          }
        }
      }

      var token = this.get('authenticationService').get();

      if (token !== undefined && token !== null && token !== '') {
        this.transitionTo('panel.general');
      } else {
        this.get('authenticationService').logoff();
        this.transitionTo('login');
      }
    }
  });
});