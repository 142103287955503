define('ember-js-getting-started/components/widget-socialmedia-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    utilService: Ember.inject.service('util-service'),

    selectedWhatsapp: false,
    selectedTelegram: false,

    gWhatsappButton: null,
    gWhatsappLink: null,
    gTelegramButton: null,
    gTelegramLink: null,

    showGenerateButton: false,

    showResults: false,

    actions: {
      keyUpPhone: function keyUpPhone(e) {
        this.set('uwhatsapp', this.get('utilService').phoneMask(this.get('uwhatsapp')));
      },
      openDataFormModal: function openDataFormModal() {
        this.set('gWhatsappButton', null);
        this.set('gWhatsappLink', null);
        this.set('gTelegramButton', null);
        this.set('gTelegramLink', null);

        if (this.get('selectedWhatsapp') === true) {
          this.set('showResults', false);

          //validate
          if (this.get('whatsapp') === "" || this.get('whatsapp') === " " || this.get('whatsapp') === undefined || this.get('whatsapp') === null) {
            alert("Preencha corretamente o campo Número do Whatsapp");
            return;
          }

          //validate
          if (this.get('message') === "" || this.get('message') === " " || this.get('message') === undefined || this.get('message') === null) {
            alert("Preencha corretamente o campo Texto da descrição do chat");
            return;
          }
        }

        if (this.get('selectedTelegram') === true) {
          //validate
          if (this.get('telegram') === "" || this.get('telegram') === " " || this.get('telegram') === undefined || this.get('telegram') === null) {
            alert("Preencha corretamente o campo Usuário do Telegram");
            return;
          }
        }

        $("#dataFormModal").modal('show');
      },
      createLink: function createLink() {
        var self = this;

        //validate
        if (this.get('uname') === "" || this.get('uname') === " " || this.get('uname') === undefined || this.get('uname') === null) {
          alert("Preencha seu Nome Corretamente.");
          return;
        }

        //validate
        if (this.get('uwhatsapp') === "" || this.get('uwhatsapp') === " " || this.get('uwhatsapp') === undefined || this.get('uwhatsapp') === null) {
          alert("Preencha Seu Número do Whatsapp Corretamente");
          return;
        }

        if (this.get('uemail') === "" || this.get('uemail') === " " || this.get('uemail') === undefined || this.get('uemail') === null) {
          alert("Preencha seu Email Corretamente.");
          return;
        }

        $('#dataFormModal').modal('hide');

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/leads/json',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: '{"name":"' + self.get('uname') + '",' + '"phone":"55' + self.get('uwhatsapp') + '",' + '"email":"' + self.get('uemail') + '"}',
          success: function success(response) {
            console.log(response);
          },
          failure: function failure(response) {
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            console.log(response);
          }
        });

        this.set('gWhatsappButton', null);
        this.set('gWhatsappLink', null);
        this.set('gTelegramButton', null);
        this.set('gTelegramLink', null);

        if (this.get('selectedWhatsapp') === true) {
          this.set('showResults', false);

          //validate
          if (this.get('whatsapp') === "" || this.get('whatsapp') === " " || this.get('whatsapp') === undefined || this.get('whatsapp') === null) {
            alert("Preencha corretamente o campo Número do Whatsapp");
            return;
          }

          //validate
          if (this.get('message') === "" || this.get('message') === " " || this.get('message') === undefined || this.get('message') === null) {
            alert("Preencha corretamente o campo Texto da descrição do chat");
            return;
          }

          var wbutton = '<link href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" rel="stylesheet"><a href="https://wa.me/' + this.get('whatsapp') + '?text=' + this.get('message') + '" style="position:fixed;width:60px;height:60px;bottom:40px;right:40px;background-color:#25d366;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888;z-index:1000;" target="_blank"><i style="margin-top:16px" class="fab fa-whatsapp"></i></a>';
          this.set('gWhatsappButton', wbutton);

          var wink = 'https://wa.me/' + this.get('whatsapp') + '?text=' + this.get('message') + '';
          this.set('gWhatsappLink', wink);

          this.set('showResults', true);
        }

        if (this.get('selectedTelegram') === true) {
          //validate
          if (this.get('telegram') === "" || this.get('telegram') === " " || this.get('telegram') === undefined || this.get('telegram') === null) {
            alert("Preencha corretamente o campo Usuário do Telegram");
            return;
          }

          var tbutton = '<link href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" rel="stylesheet"><a href="https://t.me/' + this.get('telegram') + '/" style="position:fixed;width:60px;height:60px;bottom:115px;right:40px;background-color:#0088cc;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888;z-index:1000;" target="_blank"><i style="margin-top:16px" class="fab fa-telegram-plane"></i></a>';
          this.set('gTelegramButton', tbutton);

          var tlink = 'https://t.me/' + this.get('telegram') + '/';
          this.set('gTelegramLink', tlink);

          this.set('showResults', true);
        }
      },
      selectWhatsapp: function selectWhatsapp() {
        if (this.get('selectedWhatsapp') === true) {
          this.set('selectedWhatsapp', false);
        } else {
          this.set('selectedWhatsapp', true);
        }

        if (this.get('selectedTelegram') === false && this.get('selectedWhatsapp') === false) {
          this.set('showGenerateButton', false);
        } else {
          this.set('showGenerateButton', true);
        }
      },
      selectTelegram: function selectTelegram() {
        if (this.get('selectedTelegram') === true) {
          this.set('selectedTelegram', false);
        } else {
          this.set('selectedTelegram', true);
        }

        if (this.get('selectedTelegram') === false && this.get('selectedWhatsapp') === false) {
          this.set('showGenerateButton', false);
        } else {
          this.set('showGenerateButton', true);
        }
      }
    }

  });
});