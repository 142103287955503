define('ember-js-getting-started/components/additional-user-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getTotalUsers = function getTotalUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users/additional/info',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalUsersLoading', false);
        self.set('totalUsers', response);
      },
      failure: function failure(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      }
    });
  };

  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      success: function success(response) {
        self.set('userInfoLoading', false);
        self.set('userProfile', response);
      },
      failure: function failure(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      }
    });
  };

  var getUserAdditionalList = function getUserAdditionalList(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users/additional',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      success: function success(response) {
        self.set('userAdditionalListLoading', false);
        self.set('userAdditionalList', response);
      },
      failure: function failure(response) {
        self.set('userAdditionalListLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userAdditionalListLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    routing: Ember.inject.service('-routing'),

    totalUsersLoading: true,

    userInfoLoading: true,

    userAdditionalListLoading: true,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getTotalUsers(this, token);

      getUser(this, token);

      getUserAdditionalList(this, token);
    }),

    totalUsers: function () {
      return null;
    }.property('totalUsers'),

    userProfile: function () {
      return null;
    }.property('userProfile'),

    userAdditionalList: function () {
      return null;
    }.property('userAdditionalList'),

    additional: null,

    actions: {
      getVigencyCredits: function (_getVigencyCredits) {
        function getVigencyCredits() {
          return _getVigencyCredits.apply(this, arguments);
        }

        getVigencyCredits.toString = function () {
          return _getVigencyCredits.toString();
        };

        return getVigencyCredits;
      }(function () {
        this.set('vigencyTable', true);
        this.set('tableCreditsLoading', true);
        getVigencyCredits(this, this.get('authenticationService').get());
      }),
      getExpiredCredits: function (_getExpiredCredits) {
        function getExpiredCredits() {
          return _getExpiredCredits.apply(this, arguments);
        }

        getExpiredCredits.toString = function () {
          return _getExpiredCredits.toString();
        };

        return getExpiredCredits;
      }(function () {
        this.set('vigencyTable', false);
        this.set('tableCreditsLoading', true);
        getExpiredCredits(this, this.get('authenticationService').get());
      }),
      showAddModal: function showAddModal() {
        this.set('login', '');
        $("#add").modal('show');
      },
      showRemoveModal: function showRemoveModal(additional) {
        this.set('additional', additional);
        $("#remove").modal('show');
      },
      showUpdateModal: function showUpdateModal(additional) {
        this.set('additional', additional);
        $("#update").modal('show');
      },
      create: function create() {

        //if(this.get('totalUsers.qt') + 1 > this.get('totalUsers.total')){

        //alert('Você já atingiu seu limite de usuários adicionais cadastrados.');

        //}else{

        var eqLogin = false;
        for (var i = 0; i < this.get('userAdditionalList').length; i++) {
          if (this.get('userAdditionalList')[i].email === this.get('email')) {
            eqLogin = true;
          }
        }

        if (eqLogin === true) {
          alert("Este Email já está cadastrado.");
        } else {
          if (this.get('email') === '' || this.get('email') === undefined || this.get('name') === '' || this.get('name') === undefined) {
            alert('Nome, email ou senha inválidos');
            return;
          } else {
            var self = this;

            self.set('userAdditionalListLoading', true);

            var create = JSON.stringify({ email: this.get('email'), name: this.get('name') });

            Ember.$.ajax({
              url: _environment.default.APP.connectfaceServiceUrl + '/users/additional',
              type: 'POST',
              dataType: 'json',
              async: false,
              data: create,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Auth': self.get('authenticationService').get().token
              },
              success: function success(response) {
                if (response.code === 111) {
                  self.set('userAdditionalListLoading', false);
                  $("#add").modal('hide');
                  alert('Este email já está sendo utilizado por algum usuário do connectface, utilize outro email.');
                } else {
                  self.set('userAdditionalListLoading', false);
                  self.set('userAdditionalList', response);
                  self.set('totalUsers.qt', self.get('totalUsers.qt') + 1);
                  $("#add").modal('hide');
                }
              },
              failure: function failure(response) {
                self.set('userAdditionalListLoading', false);
                console.log(response);
                $("#add").modal('hide');
              },
              error: function error(response) {
                self.set('userAdditionalListLoading', false);
                console.log(response);
                $("#add").modal('hide');
              }
            });
          }
        }

        //}
      },
      remove: function remove(additional) {
        var self = this;

        self.set('userAdditionalListLoading', true);

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/users/additional/' + additional,
          type: 'DELETE',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': self.get('authenticationService').get().token
          },
          success: function success(response) {
            self.set('userAdditionalListLoading', false);
            self.set('userAdditionalList', response);
            self.set('totalUsers.qt', self.get('totalUsers.qt') - 1);
            $("#remove").modal('hide');
          },
          failure: function failure(response) {
            self.set('userAdditionalListLoading', false);
            console.log(response);
            $("#remove").modal('hide');
          },
          error: function error(response) {
            self.set('userAdditionalListLoading', false);
            console.log(response);
            $("#remove").modal('hide');
          }
        });
      },
      update: function update() {
        var self = this;

        self.set('userAdditionalListLoading', true);

        var update = JSON.stringify({ id: this.get('additional.id'), email: this.get('additional.email'), name: this.get('additional.name') });

        var token = self.get('authenticationService').get();

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/users/additional',
          type: 'PUT',
          dataType: 'json',
          async: true,
          data: update,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            if (response.code === 111) {
              self.set('userAdditionalListLoading', false);
              $("#add").modal('hide');
              alert('Este email já está sendo utilizado por algum usuário do connectface, utilize outro email.');
              getUserAdditionalList(self, token);
            } else {
              self.set('userAdditionalListLoading', false);
              self.set('userAdditionalList', response);
              $("#update").modal('hide');
            }
          },
          failure: function failure(response) {
            self.set('userAdditionalListLoading', false);
            console.log(response);
            $("#update").modal('hide');
          },
          error: function error(response) {
            self.set('userAdditionalListLoading', false);
            console.log(response);
            $("#update").modal('hide');
          }
        });
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }

  });
});