define('ember-js-getting-started/components/billing-information-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getAutoresponderQuota = function getAutoresponderQuota(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/autoresponder',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalArLoading', false);
        self.set('autoresponder', response);
      },
      failure: function failure(response) {
        self.set('totalArLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('totalArLoading', false);
        console.log(response);
      }
    });
  };

  var getCredits = function getCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalCreditsLoading', false);
        self.set('totalCredits', response);
      },
      failure: function failure(response) {
        self.set('totalCreditsLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('totalCreditsLoading', false);
        console.log(response);
      }
    });
  };

  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('userInfoLoading', false);
        self.set('user', response);
      },
      failure: function failure(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userInfoLoading', false);
        console.log(response);
      }
    });
  };

  var getTotalUsers = function getTotalUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users/additional/info',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalUsers', response);
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  var getMachines = function getMachines(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/userMachineSlots/list',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('machinesLoading', false);
        self.set('machines', response);
      },
      failure: function failure(response) {
        self.set('machinesLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('machinesLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    routing: Ember.inject.service('-routing'),

    userInfoLoading: true,

    machinesLoading: true,

    totalCreditsLoading: true,

    totalArLoading: true,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getUser(this, token);
      getCredits(this, token);
      getAutoresponderQuota(this, token);
      getTotalUsers(this, token);
      getMachines(this, token);
    }),

    planApi: function () {
      if (this.get('authenticationService').get().extras.user.planName === 'API') {
        return true;
      } else {
        return false;
      }
    }.property('planApi'),

    isPlanStart: function () {
      if (this.get('authenticationService').get().extras.user.planName === 'Start' || this.get('authenticationService').get().extras.user.planName === 'Start B' || this.get('authenticationService').get().extras.user.planName === 'Start T') {
        return 'background-color:#e8f6e4;';
      } else {
        return 'background-color:#fff;';
      }
    }.property('isPlanStart'),

    isPlanMid: function () {
      if (this.get('authenticationService').get().extras.user.planName === 'Mid' || this.get('authenticationService').get().extras.user.planName === 'Mid B' || this.get('authenticationService').get().extras.user.planName === 'Mid T') {
        return 'background-color:#e8f6e4;';
      } else {
        return 'background-color:#fff;';
      }
    }.property('isPlanMid'),

    isPlanInfinity: function () {
      if (this.get('authenticationService').get().extras.user.planName === 'Infinity' || this.get('authenticationService').get().extras.user.planName === 'Infinity B' || this.get('authenticationService').get().extras.user.planName === 'Infinity T') {
        return 'background-color:#e8f6e4;';
      } else {
        return 'background-color:#fff;';
      }
    }.property('isPlanStart'),

    machines: function () {
      return null;
    }.property('machines'),

    totalCredits: function () {
      return null;
    }.property('totalCredits'),

    user: function () {
      return null;
    }.property('user'),

    autoresponder: function () {
      return null;
    }.property('autoresponder'),

    totalUsers: function () {
      return null;
    }.property('totalUsers'),

    actions: {
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      },
      switchPlan: function switchPlan() {
        $("#switchPlanInfo").modal('show');
      },
      cancelPlan: function cancelPlan() {
        $("#cancelPlanInfo").modal('show');
      }
    }

  });
});