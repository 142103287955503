define('ember-js-getting-started/components/apiform-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    utilService: Ember.inject.service('util-service'),

    stepEmail: true,
    stepForm: false,
    stepOrderCreated: false,

    loading: false,

    actions: {
      keyUpPressCpf: function keyUpPressCpf(e) {
        this.set('cpf', this.get('utilService').cpfMask(this.get('cpf')));
      },
      keyUpPhone: function keyUpPhone(e) {
        this.set('phone', this.get('utilService').phoneMask(this.get('phone')));
      },
      keyUpCep: function keyUpCep(e) {
        this.set('postalCode', this.get('utilService').onlyNumber(this.get('postalCode')));
      },
      keyUpNumber: function keyUpNumber(e) {
        this.set('number', this.get('utilService').onlyNumber(this.get('number')));
      },
      goToForm: function goToForm() {
        var self = this;
        self.set('loading', true);
        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/logins/' + self.get('email') + '/validate',
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          success: function success(response) {
            if (response.code === 1) {
              self.set('loading', false);
              self.set('messageResponse1', 'Digite um formato de email válido');
            }

            // nao tem pedido ativo no whatsmaker vai pro form
            if (response.code === 2 || response.code === 5) {
              self.set('loading', false);
              self.set('messageResponse1', null);
              self.set('messageResponse2', null);
              self.set('stepForm', true);
              self.set('stepEmail', false);
              self.set('orderCreated', false);
            }

            // nao faz nada pq o cara ja esta ativado
            if (response.code === 3) {
              self.set('loading', false);
              self.set('messageResponse2', 'Sua licença do Whatsmaker está ativa, Acesse seu painel e vá em API.');
              console.log(response);
            }
          },
          failure: function failure(response) {
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      create: function create() {
        var self = this;
        self.set('loading', true);
        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/whatsmaker/api',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: '{"email":"' + self.get('email') + '",' + '"fullName":"' + self.get('fullName') + '",' + '"cpf":"' + self.get('cpf') + '",' + '"phone":"' + self.get('phone') + '",' + '"postalCode":"' + self.get('postalCode') + '",' + '"number":"' + self.get('number') + '",' + '"complement":"' + self.get('complement') + '",' + '"district":"' + self.get('district') + '",' + '"city":"' + self.get('city') + '",' + '"address":"' + self.get('address') + '",' + '"state":"' + self.get('state') + '"}',
          success: function success(response) {
            self.set('loading', false);
            // instrução invalida
            if (response.code === 3 || response.code === 15) {
              alert("NÃO AUTORIZADO - O aplicativo não está disponível para as versões gratuitas do CuteCute. Para mais informações, acesse o suporte da APC Software pelo link: https://apcsoftware.com.br/cutecute/#contact");
            } else {
              if (response.code === 1) {
                self.set('messageResponse1', response.message);
              } else {
                self.set('stepForm', false);
                self.set('stepEmail', false);
                self.set('stepOrderCreated', true);
              }
            }
          },
          failure: function failure(response) {
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            console.log(response);
          }
        });
      }
    }

  });
});