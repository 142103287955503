define('ember-js-getting-started/router', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('cutecute');
    this.route('page-not-found', { path: '*path' });

    this.route('panel', function () {
      this.route('general');
      this.route('user-area');
      this.route('billing-information');
      this.route('download');
      this.route('mem');
      this.route('credit');
      this.route('help');
      this.route('learn');
      this.route('buy-credit');
      this.route('autoresponder-cota');
      this.route('buy-autoresponder');
      this.route('additional-user');
      this.route('machine-manager');
      this.route('buy-machine');
      this.route('billing-detail');
      this.route('notification');
      this.route('emoji');
      //this.route('api');
      this.route('live');
    });

    this.route('infos', function () {
      this.route('emojis');
      this.route('general');
      this.route('tutorials');
      this.route('notifications');
    });

    this.route('apiform');

    this.route('support');
    this.route('create-whatsapp-link');
    // this.route('gerador-link-whatsapp');
    this.route('widget-socialmedia');
    this.route('public-download');
  });

  exports.default = Router;
});