define('ember-js-getting-started/components/panel-navigation-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getPanelInfo = function getPanelInfo(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/panelNavigation',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('panelInfoLoading', false);
        self.set('panelInfo', response);
      },
      failure: function failure(response) {
        self.set('panelInfoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('machinesLoading', false);
        console.log(panelInfoLoading);
      }
    });
  };

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    clickToCleanNotification: 0,

    totalCreditsLoading: true,
    totalArLoading: true,
    machinesLoading: true,
    userLoading: true,
    totalUsersLoading: true,
    panelInfoLoading: true,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);
      this.set('user', token.extras.user);
      this.set('planExpired', token.expired);

      getPanelInfo(this, token);
    }),

    isPlanInfinity: function () {
      if (this.get('authenticationService').get().extras.user.planName === 'Infinity' || this.get('authenticationService').get().extras.user.planName === 'Infinity B' || this.get('authenticationService').get().extras.user.planName === 'Infinity T') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanInfinity'),

    planApi: function () {
      if (this.get('authenticationService').get().extras.user.planName === 'API') {
        return true;
      } else {
        return false;
      }
    }.property('planApi'),

    planExpired: function () {
      return null;
    }.property('planExpired'),

    panelInfo: function () {
      return null;
    }.property('panelInfo'),

    userAdditional: function () {
      return null;
    }.property('userAdditional'),

    user: function () {
      return null;
    }.property('user'),

    isBusiness: function () {
      if (this.get('user.planName') === "Business" || this.get('user.planName') === "Advanced" || this.get('user.planName') === "Plus" || this.get('user.planName') === "Special" || this.get('user.planName') === "Enterprise" || this.get('user.planName') === "Enterprise B" || this.get('user.planName') === "Enterprise T" || this.get('user.planName') === "Free") {
        return true;
      } else {
        //agora todos os planos foram liberados para whatsapp de suporte
        return true;
      }
    }.property('isBusiness'),

    actions: {
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toWhatsapp: function toWhatsapp() {
        var email = this.get('user.email');
        var url = "https://api.whatsapp.com/send?phone=5511969787758&text=Solicito suporte para o email de cadastro: " + email;
        window.open(url);
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      },
      logoff: function logoff() {
        this.get('authenticationService').logoff(this);
        this.get('routing').transitionTo('login');
      },
      hideNumberBell: function hideNumberBell() {
        this.set('panelInfo.unreadNotifications', 0);
      },
      cleanNotification: function cleanNotification() {
        alert('teste');
        this.set('clickToCleanNotification', this.get('clickToCleanNotification') + 1);
        //sefor par
        if (this.get('clickToCleanNotification') / 2 === 0) {
          this.set('panelInfoLoading', true);
          var token = this.get('authenticationService').get();
          var self = this;
          Ember.$.ajax({
            url: _environment.default.APP.connectfaceServiceUrl + '/panelNavigation/notifications/read',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: null,
            success: function success(response) {
              self.set('panelInfoLoading', false);
              self.set('panelInfo', response);
            },
            failure: function failure(response) {
              self.set('panelInfoLoading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('machinesLoading', false);
              console.log(panelInfoLoading);
            }
          });
        }
      },
      readAllNotifications: function readAllNotifications() {
        this.set('panelInfoLoading', true);
        var token = this.get('authenticationService').get();
        var self = this;
        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/panelNavigation/notifications/read',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: null,
          success: function success(response) {
            self.set('panelInfoLoading', false);
            self.set('panelInfo', response);
          },
          failure: function failure(response) {
            self.set('panelInfoLoading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('machinesLoading', false);
            console.log(panelInfoLoading);
          }
        });
      }
    }
  });
});