define("ember-js-getting-started/helpers/format-date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;
  function formatDate(params /*, hash*/) {
    var value = params[0];
    if (value !== null && value !== "") {
      var d = new Date(value),
          dformat = ("00" + d.getDate()).slice(-2) + "/" + ("00" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear() + " " + ("00" + d.getHours()).slice(-2) + ":" + ("00" + d.getMinutes()).slice(-2);
      return dformat;
    }
    return null;
  }

  exports.default = Ember.Helper.helper(formatDate);
});