define("ember-js-getting-started/helpers/format-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNumber = formatNumber;
  function formatNumber(params /*, hash*/) {
    var v = params[0];
    return v.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  exports.default = Ember.Helper.helper(formatNumber);
});