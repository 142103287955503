define('ember-js-getting-started/helpers/user-extra-machines', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.userExtraMachines = userExtraMachines;
  function userExtraMachines(params /*, hash*/) {
    return parseInt(params[0]) - 1;
  }

  exports.default = Ember.Helper.helper(userExtraMachines);
});