define("ember-js-getting-started/helpers/cpf-mask", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cfpMask = cfpMask;
  function cfpMask(params /*, hash*/) {
    var cpf = params[0];
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    return cpf;
  }

  exports.default = Ember.Helper.helper(cfpMask);
});