define('ember-js-getting-started/components/mem-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getTotalApiCredits = function getTotalApiCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits/api',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalApiCreditsLoading', false);
        self.set('totalApiCredits', response);
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalApiCreditsLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalApiCreditsLoading', false);
      }
    });
  };

  var getTotalCredits = function getTotalCredits(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/credits',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalCreditsLoading', false);
        self.set('totalCredits', response);
        //$("#chernobyl").modal('show');
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalCreditsLoading', false);
      }
    });
  };

  var getAutoresponderQuota = function getAutoresponderQuota(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/autoresponder',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalArLoading', false);
        self.set('totalAr', response);
      },
      failure: function failure(response) {
        console.log(response);
        self.set('totalArLoading', false);
      },
      error: function error(response) {
        console.log(response);
        self.set('totalArLoading', false);
      }
    });
  };

  var getUser = function getUser(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('userLoading', false);
        self.set('user', response);
        if (self.get('user.planName') === 'API') {
          self.set('planApi', true);
        } else {
          self.set('planApi', false);
        }
      },
      failure: function failure(response) {
        self.set('userLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('userLoading', false);
        console.log(response);
      }
    });
  };

  var getTotalUsers = function getTotalUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/users/additional/info',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('totalUsersLoading', false);
        self.set('totalUsers', response);
      },
      failure: function failure(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('totalUsersLoading', false);
        console.log(response);
      }
    });
  };

  var getMachines = function getMachines(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/userMachineSlots/list',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('machinesLoading', false);
        self.set('machines', response);
      },
      failure: function failure(response) {
        self.set('machinesLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('machinesLoading', false);
        console.log(response);
      }
    });
  };

  var getUserBilling = function getUserBilling(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/userBilling?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('billing', response);
        self.set('numberBilling', response.userBilling.length);

        var plan = self.get('authenticationService').get().extras.user.planName;

        var purchDate = self.get('authenticationService').get().extras.user.purchaseDate;;
        var today = new Date();
        var diffInMs = new Date(today) - new Date(purchDate);
        var diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        if (plan === 'Start') {
          if (self.get('numberBilling') >= 1) {
            self.set('linkEbook1', '<a href="https://drive.google.com/file/d/146m9-xPNsJvsRVid1dGJKlk-CGSzOM_B/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 2) {
            self.set('linkEbook2', '<a href="https://drive.google.com/file/d/1FbqXojZg3sWiGIafVOcXqxP2c1WaJAFG/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 3) {
            self.set('linkEbook3', '<a href="https://drive.google.com/file/d/1ZuuNTQJvz6fMs8GCiz2XZoQe-ImQ5CMX/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 4) {
            self.set('linkEbook4', '<a href="https://drive.google.com/file/d/1gVDWebicf4W5G5rC9tzy60XRlkrHQbmF/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 5) {
            self.set('linkEbook5', '<a href="https://drive.google.com/file/d/14pSG-3n9FuSzU4Z56P178y_gXLwNeM5I/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 6) {
            self.set('linkEbook6', '<a href="https://drive.google.com/file/d/1esNQ03pNzD5vM89EHJCOURoT1mx646HI/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 7) {
            self.set('linkEbook7', '<a href="https://drive.google.com/file/d/1cWJC8h5XCzmeY63QzzhaUsAGUKWvMIrB/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 8) {
            self.set('linkEbook8', '<a href="https://drive.google.com/file/d/1sSAZq-XYhe-g2xMdGbGi2HpYnr1jxUR_/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 9) {
            self.set('linkEbook9', '<a href="https://drive.google.com/file/d/1eLf02Qcu8z0vxijgvGqBytAoYyAbvQ8x/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 10) {
            self.set('linkEbook10', '<a href="https://drive.google.com/file/d/1layLkuAcuydMwI0R1TfHj-Iw9r4v3i6n/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 11) {
            self.set('linkEbook11', '<a href="https://drive.google.com/file/d/1Vgpe-cvUnkR-1cTItk8eK-5-be9X62wE/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 12) {
            self.set('linkEbook12', '<a href="https://drive.google.com/file/d/1tNqwtDkuUC4wU8taie2ML8jCm9R59eAo/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
        }

        if (plan === 'Mid') {
          if (self.get('numberBilling') >= 1) {
            self.set('linkEbook1', '<a href="https://drive.google.com/file/d/1-SvtJ7kMtklmE7pkAX0afH9pkZRMp2js/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 2) {
            self.set('linkEbook2', '<a href="https://drive.google.com/file/d/1rsN58_zaOkqHPtDMwMjUB8jCqJdLbgpb/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 3) {
            self.set('linkEbook3', '<a href="https://drive.google.com/file/d/1yQain8ykjd3CLz42G2CphPgBMwSAfLSr/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 4) {
            self.set('linkEbook4', '<a href="https://drive.google.com/file/d/134OtYeelm0hOm5AZ-joEYjubwqN7cSGy/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 5) {
            self.set('linkEbook5', '<a href="https://drive.google.com/file/d/1zEdRZlFbFuAycEv67GeYGRpwPXpVYH38/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 6) {
            self.set('linkEbook6', '<a href="https://drive.google.com/file/d/1NpJHrmpGbfUhd28VcOH9zEnYV6BwaKSX/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 7) {
            self.set('linkEbook7', '<a href="https://drive.google.com/file/d/1qj1sCuZmpvR5VAxlrjOj9Wd-ME3FMuKM/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 8) {
            self.set('linkEbook8', '<a href="https://drive.google.com/file/d/1Hix8OzQa0nq4ATjPc3aWnlr2Wt27AUcJ/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 9) {
            self.set('linkEbook9', '<a href="https://drive.google.com/file/d/1iuSEEGjJbTUsgBXgzCvcWjakDmawmbfl/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 10) {
            self.set('linkEbook10', '<a href="https://drive.google.com/file/d/1jfww8sGlp6aoxxf0KleDG2oBeHxt2Ykm/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 11) {
            self.set('linkEbook11', '<a href="https://drive.google.com/file/d/1VhBLULyAY3GWK3sPzuHvDGx_SQbWYBHO/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
          if (self.get('numberBilling') >= 12) {
            self.set('linkEbook12', '<a href="https://drive.google.com/file/d/1wBtcLIMvYRDynBN-PKz_xTg4z_NoiGHe/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
        }

        if (plan === 'Infinity') {
          if (self.get('numberBilling') >= 1) {
            self.set('linkEbook1', '<a href="https://drive.google.com/file/d/1y6frXNOlzHt3QPv5Q5NxcScdIyn4gM6Z/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 2) {
            self.set('linkEbook2', '<a href="https://drive.google.com/file/d/1fDGZETKdxaSH6USPpwBIT226bUvEIju7/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 3) {
            self.set('linkEbook3', '<a href="https://drive.google.com/file/d/1US5G5P0I5UAKHvkAA4RC8pFHEn_zlx1n/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 4) {
            self.set('linkEbook4', '<a href="https://drive.google.com/file/d/15pEwCxkWlo6_brqq3J8SB-0GbjhPuu2I/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 5) {
            self.set('linkEbook5', '<a href="https://drive.google.com/file/d/1PFu9tWu9U5ZqhS1z1doLa5IFCQL6PHBt/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 6) {
            self.set('linkEbook6', '<a href="https://drive.google.com/file/d/1-fxJeW1zmf2cjTidyZTS8A7_yc7pZV5s/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 7) {
            self.set('linkEbook7', '<a href="https://drive.google.com/file/d/1V_v6Qk2GzghmIsoojsms9QZKLjRtcgWi/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 8) {
            self.set('linkEbook8', '<a href="https://drive.google.com/file/d/1yw-q0ZFwnQ4uJEkoV6wk_1wnSItArySk/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 9) {
            self.set('linkEbook9', '<a href="https://drive.google.com/file/d/1K5Kl11HG_exutwWw8tKHTJMgc84l0OvF/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 10) {
            self.set('linkEbook10', '<a href="https://drive.google.com/file/d/1tgDm7q7FUhvrcVNbv5SfpCGaru00XkO2/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 11) {
            self.set('linkEbook11', '<a href="https://drive.google.com/file/d/1XIWuisaVEq9oCgAZYrvR42Hhpq_ZZnTM/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
          if (self.get('numberBilling') >= 12) {
            self.set('linkEbook12', '<a href="https://drive.google.com/file/d/1KN2cMd1XyI25X7SHqqH2fhGaVjaOFe4M/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
        }

        if (plan === 'Start A') {
          self.set('linkEbook1', '<a href="https://drive.google.com/file/d/1fuchys4BqkfvXpqsTu8CICJ_pAOWnyYi/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          if (diffInDays >= 30) {
            self.set('linkEbook2', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook3', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook4', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook5', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook6', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook7', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook8', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook9', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook10', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook11', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
            self.set('linkEbook12', '<a href="https://drive.google.com/file/d/1No7jb7GltNfcddZJCvPWRfRzOqoBbe3b/view?usp=share_link" target="_blank">Volume 1 (Vol. 2 e 3 não incluso)</a>');
          }
        }

        if (plan === 'Mid A') {
          self.set('linkEbook1', '<a href="https://drive.google.com/file/d/1anWUnjBqf7qCpY2-hxtzQLGzCz88gOsd/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          if (diffInDays >= 30) {
            self.set('linkEbook2', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook3', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook4', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook5', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook6', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook7', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook8', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook9', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook10', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook11', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
            self.set('linkEbook12', '<a href="https://drive.google.com/file/d/18dSqKvUkKQp6LlPbXqaTzxPtPeeyTw9H/view?usp=share_link" target="_blank">Volumes 1 e 2 (Vol. 3 não incluso)</a>');
          }
        }

        if (plan === 'Infinity A') {
          self.set('linkEbook1', '<a href="https://drive.google.com/file/d/1vIzubKv41BiY-50WVpwT62ZpewMI4nlT/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          if (diffInDays >= 30) {
            self.set('linkEbook2', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook3', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook4', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook5', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook6', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook7', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook8', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook9', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook10', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook11', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
            self.set('linkEbook12', '<a href="https://drive.google.com/file/d/1xishfG5a27IQTniW-GPNYyMyJllYQsHU/view?usp=share_link" target="_blank">Volumes 1, 2 e 3');
          }
        }
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    totalCreditsLoading: true,
    totalArLoading: true,
    machinesLoading: true,
    userLoading: true,
    totalUsersLoading: true,
    totalApiCreditsLoading: true,
    numberBilling: 0,
    loading: true,
    searchPerPage: 12,
    searchPage: 1,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);
      this.set('planExpired', token.expired);

      getUser(this, token);
      getUserBilling(this, token);
    }),

    linkEbook1: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook1'),

    linkEbook2: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook2'),

    linkEbook3: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook3'),

    linkEbook4: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook4'),

    linkEbook5: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook5'),

    linkEbook6: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook6'),

    linkEbook7: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook7'),

    linkEbook8: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook8'),

    linkEbook9: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook9'),

    linkEbook10: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook10'),

    linkEbook11: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook11'),

    linkEbook12: function () {
      return '- Aguardando liberação... <br>';
    }.property('linkEbook12'),

    isPlanInfinityA: function () {
      var plan = this.get('authenticationService').get().extras.user.planName;
      if (plan === 'Infinity A') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanInfinityA'),

    isPlanInfinity: function () {
      var plan = this.get('authenticationService').get().extras.user.planName;
      if (plan === 'Infinity') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanInfinity'),

    isPlanMidA: function () {
      var plan = this.get('authenticationService').get().extras.user.planName;
      if (plan === 'Mid A') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanMidA'),

    isPlanMid: function () {
      var plan = this.get('authenticationService').get().extras.user.planName;
      if (plan === 'Mid') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanMid'),

    isPlanStartA: function () {
      var plan = this.get('authenticationService').get().extras.user.planName;
      if (plan === 'Start A') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanStartA'),

    isPlanStart: function () {
      var plan = this.get('authenticationService').get().extras.user.planName;
      if (plan === 'Start') {
        return true;
      } else {
        return false;
      }
    }.property('isPlanStart'),

    planApi: function () {
      return true;
    }.property('planApi'),

    planExpired: function () {
      return null;
    }.property('planExpired'),

    userAdditional: function () {
      return null;
    }.property('userAdditional'),

    totalCredits: function () {
      return null;
    }.property('totalCredits'),

    totalApiCredits: function () {
      return null;
    }.property('totalApiCredits'),

    totalAr: function () {
      return null;
    }.property('totalAr'),

    totalMachines: function () {
      return null;
    }.property('totalMachines'),

    user: function () {
      return null;
    }.property('user'),

    totalUsers: function () {
      return null;
    }.property('totalUsers'),

    actions: {
      toForgram: function toForgram() {
        window.open("https://forgram.com.br/", "_self");
      },
      reactivate: function reactivate() {
        this.get('authenticationService').logoff(this);
        window.open("https://purchase.hotmart.com/purchase", "_self");
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toMem: function toMem() {
        this.get('routing').transitionTo('panel.mem');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      },
      toEmojis: function toEmojis() {
        this.get('routing').transitionTo('panel.emoji');
      },
      toApi: function toApi() {
        this.get('routing').transitionTo('panel.api');
      },
      closeCommunit: function closeCommunit() {
        if (this.get('authenticationService').get().extras.user.planName === 'Infinity' || this.get('authenticationService').get().extras.user.planName === 'Infinity B' || this.get('authenticationService').get().extras.user.planName === 'Infinity T' || this.get('authenticationService').get().extras.user.planName === 'Mid' || this.get('authenticationService').get().extras.user.planName === 'Mid B' || this.get('authenticationService').get().extras.user.planName === 'Mid T') {
          window.open("https://www.facebook.com/groups/2012075535562230/", "_blank");
        } else {
          $("#closeCommunityModal").modal('show');
        }
      },
      toLive: function toLive() {
        //$("#learnWating").modal('show');
        this.get('routing').transitionTo('panel.learn');
      },
      showNfeModal: function showNfeModal() {
        var self = this;
        var token = self.get('authenticationService').get();

        self.set('nfeModalLoading', true);
        $('#showNfeModal').modal('show');

        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/nfes',
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('nfeModalLoading', false);
            self.set('nfeList', response);
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('nfeModalLoading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('nfeModalLoading', false);
            console.log(response);
          }
        });
      }
    }

  });
});