define('ember-js-getting-started/routes/page-not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect() {
      this.transitionTo('panel.general');
    }
  });
});