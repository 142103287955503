define('ember-js-getting-started/components/live-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getLearnVideo = function getLearnVideo(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/learns/video',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('learnVideoLoading', false);
        self.set('learnVideos', response);
      },
      failure: function failure(response) {
        self.set('learnVideoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('learnVideoLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),

    authenticationService: Ember.inject.service('auth-token-service'),

    learnVideoLoading: true,

    objectVideoModal: null,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);
      getLearnVideo(this, token);
    }),

    learnVideos: function () {
      return null;
    }.property('learnVideos'),

    actions: {
      openVideo: function openVideo(video) {
        console.log(video);
        this.set('objectVideoModal', video);
        $("#learnVideoModal").modal('show');
      }
    }

  });
});