define('ember-js-getting-started/components/infos-tutorials-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getHelpVideoPaginate = function getHelpVideoPaginate(self) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/helps/video/paginate?' + '&page=' + 1 + '&perPage=' + 25 + '&search=' + self.get('searchVideo'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('helpVideoLoading', false);
        self.set('helpVideos', response);
      },
      failure: function failure(response) {
        self.set('helpVideoLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('helpVideoLoading', false);
        console.log(response);
      }
    });
  };

  var getHelpFaqPaginate = function getHelpFaqPaginate(self) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/helps/faq/paginate?' + '&page=' + 1 + '&perPage=' + 25 + '&search=' + self.get('searchFaq'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('helpFaqLoading', false);
        self.set('helpFaqs', response);
      },
      failure: function failure(response) {
        self.set('helpFaqLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('helpFaqLoading', false);
        console.log(response);
      }
    });
  };

  var countVisitFaq = function countVisitFaq(self, id) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/helps/faq/' + id + '/visitCount',
      type: 'POST',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {},
      failure: function failure(response) {},
      error: function error(response) {}
    });
  };

  var countVisitTuto = function countVisitTuto(self, id) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/helps/tuto/' + id + '/visitCount',
      type: 'POST',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {},
      failure: function failure(response) {},
      error: function error(response) {}
    });
  };

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),

    //authenticationService: Ember.inject.service('auth-token-service'),

    helpVideoLoading: true,

    helpFaqLoading: true,

    objectVideoModal: null,

    objectFaqModal: null,

    searchFaq: null,

    searchVideo: null,

    initComponente: Ember.on('init', function () {
      //var token = this.get('authenticationService').get();
      //this.set('userAdditional', token.extras.user.userAdditional);

      getHelpVideoPaginate(this);

      getHelpFaqPaginate(this);
    }),

    helpVideos: function () {
      return null;
    }.property('helpVideos'),

    helpFaqs: function () {
      return null;
    }.property('helpFaqs'),

    actions: {
      openFaq: function openFaq(faq) {
        console.log(faq);
        this.set('objectFaqModal', faq);
        countVisitFaq(this, faq.id);
        $("#helpFaqModal").modal('show');
      },
      openTutorial: function openTutorial(video) {
        console.log(video);
        this.set('objectTutorialModal', video);
        //countVisitFaq(this, faq.id);
        $("#helpTutorialModal").modal('show');
      },
      tuto0: function tuto0() {
        countVisitTuto(this, 1);
        $("#tuto0").modal('show');
      },
      keyUpSearchFaq: function keyUpSearchFaq() {
        this.set('helpFaqLoading', true);
        getHelpFaqPaginate(this);
      },
      keyUpSearchVideo: function keyUpSearchVideo() {
        this.set('helpVideoLoading', true);
        getHelpVideoPaginate(this);
      }
    }

  });
});