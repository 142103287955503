define('ember-js-getting-started/helpers/format-bool-conectado-desconectado', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatBoolConectadoDesconectado = formatBoolConectadoDesconectado;
  function formatBoolConectadoDesconectado(params /*, hash*/) {
    var value = params[0];
    if (value === true) return 'Conectado';else return 'Desconectado';
  }

  exports.default = Ember.Helper.helper(formatBoolConectadoDesconectado);
});