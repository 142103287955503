define('ember-js-getting-started/components/buy-autoresponder-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getAutoresponderPackages = function getAutoresponderPackages(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.connectfaceServiceUrl + '/autoresponder/packages',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('autoresponderPackagesLoading', false);
        self.set('autoresponderPackages', response);
      },
      failure: function failure(response) {
        self.set('autoresponderPackagesLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('autoresponderPackagesLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    cartService: Ember.inject.service('cart-service'),
    routing: Ember.inject.service('-routing'),

    autoresponderPackagesLoading: true,

    buyLoading: false,

    autoresponderPackages: function () {
      return null;
    }.property('autoresponderPackages'),

    cartItens: function () {
      return null;
    }.property('cartItens'),

    viewCart: function () {
      return null;
    }.property('cartItens'),

    cartTotalPrice: 0,
    cartTotalCotas: 0,

    cartTotalPrice2: 0,
    singleCreditPrice: 0,

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userAdditional', token.extras.user.userAdditional);

      getAutoresponderPackages(this, token);

      this.set('cartItens', new Array());
    }),

    actions: {
      addToCart2: function addToCart2(pack) {
        var inCart = false;
        this.set('viewCart', null);

        if (this.get('cartItens').length > 0) {
          for (var i = 0; i < this.get('cartItens').length; i++) {
            if (pack !== undefined && this.get('cartItens') !== undefined) {
              if (pack.id === this.get('cartItens')[i].id) {
                inCart = true;
                Ember.set(this.get('cartItens')[i], "qt", this.get('cartItens')[i].qt + 1);
              }
            }
          }
        }

        //add novos itens repetidos
        if (inCart === true) {
          this.set('cartTotalCotas', this.get('cartTotalCotas') + pack.autoresponderPackage.quantity);
          this.set('viewCart', this.get('cartItens'));
        }

        //add o primeiro item
        if (inCart === false) {
          Ember.set(pack, "qt", 1);
          this.get('cartItens').pushObject(pack);
          this.set('cartTotalCotas', this.get('cartTotalCotas') + pack.autoresponderPackage.quantity);
          this.set('viewCart', this.get('cartItens'));
        }

        for (var i = 0; i < this.get('autoresponderPackages').length; i++) {
          if (this.get('autoresponderPackages') !== undefined) {
            if (this.get('cartTotalCotas') >= this.get('autoresponderPackages')[i].autoresponderPackage.quantity) {
              this.set('cartTotalPrice2', this.get('cartTotalCotas') * (this.get('autoresponderPackages')[i].value / this.get('autoresponderPackages')[i].autoresponderPackage.quantity));
              this.set('singleCreditPrice', this.get('autoresponderPackages')[i].value / this.get('autoresponderPackages')[i].autoresponderPackage.quantity);
            }
          }
        }
      },
      removeFromCart2: function removeFromCart2(pack) {
        this.set('viewCart', null);

        this.set('cartTotalCotas', this.get('cartTotalCotas') - pack.autoresponderPackage.quantity);

        if (this.get('cartItens').length > 0) {
          for (var i = 0; i < this.get('cartItens').length; i++) {
            if (pack !== undefined && this.get('cartItens') !== undefined) {
              if (pack.id === this.get('cartItens')[i].id) {
                if (this.get('cartItens')[i].qt > 0) {
                  if (this.get('cartItens')[i].qt >= 1) {
                    Ember.set(this.get('cartItens')[i], "qt", this.get('cartItens')[i].qt - 1);
                  }
                  if (this.get('cartItens')[i].qt === 0) {
                    var toRemove = this.get('cartItens').findBy('id', pack.id);
                    if (!toRemove) {
                      // deal with error
                      return;
                    }
                    this.get('cartItens').removeObject(toRemove);
                  }
                }
              }
            }
          }

          for (var i = 0; i < this.get('autoresponderPackages').length; i++) {
            if (this.get('autoresponderPackages') !== undefined) {
              if (this.get('cartTotalCotas') === 0) {
                this.set('cartTotalPrice2', 0);
                this.set('singleCreditPrice', 0);
              }

              if (this.get('cartTotalCotas') >= this.get('autoresponderPackages')[i].autoresponderPackage.quantity) {
                this.set('cartTotalPrice2', this.get('cartTotalCotas') * (this.get('autoresponderPackages')[i].value / this.get('autoresponderPackages')[i].autoresponderPackage.quantity));
                this.set('singleCreditPrice', this.get('autoresponderPackages')[i].value / this.get('autoresponderPackages')[i].autoresponderPackage.quantity);
              }
            }
          }
        }

        this.set('viewCart', this.get('cartItens'));
      },
      buy: function buy() {
        this.set('buyLoading', true);
        var self = this;
        var checkout = _environment.default.APP.checkoutUrl;
        Ember.$.ajax({
          url: _environment.default.APP.connectfaceServiceUrl + '/shopping/autoresponderCart/create',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': this.get('authenticationService').get().token
          },
          data: JSON.stringify(this.get('viewCart')),
          success: function success(response) {
            var url = checkout + '/app/cfwhats&cart=' + response.cartKey;
            window.location.assign(url);
          },
          failure: function failure(response) {
            self.set('buyLoading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('buyLoading', false);
            console.log(response);
          }
        });
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      },
      toBillingInformation: function toBillingInformation() {
        this.get('routing').transitionTo('panel.billing-information');
      },
      toCredits: function toCredits() {
        this.get('routing').transitionTo('panel.credit');
      },
      toDownloads: function toDownloads() {
        this.get('routing').transitionTo('panel.download');
      },
      toHelp: function toHelp() {
        this.get('routing').transitionTo('panel.help');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toMachineManager: function toMachineManager() {
        this.get('routing').transitionTo('panel.machine-manager');
      },
      toUsers: function toUsers() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toAutoresponder: function toAutoresponder() {
        this.get('routing').transitionTo('panel.autoresponder-cota');
      },
      toAdditional: function toAdditional() {
        this.get('routing').transitionTo('panel.additional-user');
      },
      toBuyCredit: function toBuyCredit() {
        this.get('routing').transitionTo('panel.buy-credit');
      },
      toBuyAutoresponder: function toBuyAutoresponder() {
        this.get('routing').transitionTo('panel.buy-autoresponder');
      },
      toBuyMachine: function toBuyMachine() {
        this.get('routing').transitionTo('panel.buy-machine');
      },
      toNotifications: function toNotifications() {
        this.get('routing').transitionTo('panel.notification');
      }
    }
  });
});