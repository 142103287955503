define('ember-js-getting-started/routes/application', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    beforeModel: function beforeModel() {
      var url_string = window.location.href;
      var http = url_string.split('://')[0];
      if (http === 'http') {
        var localhost = url_string.split('://')[1];
        var localhost = url_string.split('/#/')[0];
        if (localhost === 'http://localhost:4200/' || localhost === 'http://localhost:4200') {
          //nada
        } else {
          if (localhost === 'https://gerador.socialmaker.com.br/' || localhost === 'https://gerador.socialmaker.com.br') {
            window.location.replace("https://gerador.socialmaker.com.br/#/widget-socialmedia");
          } else {
            //window.location.replace("https://painel.socialmaker.com.br/");
          }
        }
      }
    },


    actions: {
      willTransition: function willTransition(transition) {
        console.log("will: " + transition);
      }
    }
  });
});