define('ember-js-getting-started/components/infos-general-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),

    actions: {
      toInfosEmojis: function toInfosEmojis() {
        this.get('routing').transitionTo('infos.emojis');
      },
      toTutorials: function toTutorials() {
        this.get('routing').transitionTo('infos.tutorials');
      }
    }
  });
});